import React from 'react'
import { linkResolver } from './linkResolver'

export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_MAIN_REPOSITORY_NAME,
    linkResolver,
    componentResolver: {
      blogpost: React.lazy(() => import('../templates/blogpost')),
      member_page: React.lazy(() => import('../templates/memberPage')),
      page: React.lazy(() => import('../templates/page')),
      project: React.lazy(() => import('../templates/project')),
      publication: React.lazy(() => import('../templates/publication'))
    }
  }
]
