import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { Auth0Provider } from '@auth0/auth0-react'
import { navigate } from 'gatsby'

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'
import htmlSerializer from './src/utils/htmlSerializer'

// Styles
import './src/styles/default.css'

const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || '/dashboard/', { replace: true })
}

export const wrapRootElement = ({ element }) => (
  <Auth0Provider domain={process.env.GATSBY_AUTH0_DOMAIN} clientId={process.env.GATSBY_AUTH0_CLIENTID} redirectUri={window.location.origin} onRedirectCallback={onRedirectCallback}>
    <PrismicProvider
      linkResolver={linkResolver}
      externalLinkComponent={(props) => (
        <a data-location='external' {...props}>
          {props.children}
        </a>
      )}
      internalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}
      richTextComponents={htmlSerializer}>
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
    </PrismicProvider>
  </Auth0Provider>
)
