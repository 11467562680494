import React from 'react'

const htmlSerializer = (type, node, text, children, key) => {
  var linkid = text?.toLowerCase().replace(/[^A-Za-z0-9]/g, '')
  switch (type) {
    case 'heading1':
      return (
        <h1 key={key} id={linkid}>
          {children}
        </h1>
      )
    case 'heading2':
      return (
        <h2 key={key} id={linkid}>
          {children}
        </h2>
      )
    case 'heading3':
      return (
        <h3 key={key} id={linkid}>
          {children}
        </h3>
      )
    case 'heading4':
      return (
        <h4 key={key} id={linkid}>
          {children}
        </h4>
      )
    case 'heading5':
      return (
        <h5 key={key} id={linkid}>
          {children}
        </h5>
      )
    case 'heading6':
      return (
        <h6 key={key} id={linkid}>
          {children}
        </h6>
      )
    case 'image':
      if (node.copyright) {
        return (
          <div key={key} className='relative copyright'>
            <img src={node.url} alt={node.alt} />
            <span className='absolute bottom-0 right-0 text-white bg-black opacity-50 m-2 px-2 py-1 text-xs rounded-sm'>{node.copyright}</span>
          </div>
        )
      } else if (node.linkTo?.link_type === 'Media') {
        return (
          <a key={key} className='inline-block text-center thumbnail mx-2 flex-auto' href={node.linkTo.url}>
            <img className='' src={node.url} alt={node.alt} />
            <span className='text-sm'>{node.alt}</span>
          </a>
        )
      } else {
        return (
          <div key={key} className='relative no-copyright'>
            <img src={node.url} alt={node.alt} />
          </div>
        )
      }
    default:
      return null
  }
}

export default htmlSerializer
